import React from "react";
import Layout from "../components/layout";
import '../assets/styles/pages/404.scss';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {SEO} from "../components";
import {Helmet} from "react-helmet";

const NotFoundPage = () => (
  <Layout>
      <Helmet title='404 Page Not Found'/>

      <div className='notFoundWrapper'>
          <h1>It seems this Room is under construction and temporarily closed</h1>
          <div className='helpLinks'>
              <h3>Maybe this links can help you</h3>
              <AniLink to={'/'} fade duration={0.7}>Home</AniLink>
              <AniLink to={'/artists/'} fade duration={0.7}>Artists Room</AniLink>
              <AniLink to={'/projects/'} fade duration={0.7}>Projects Room</AniLink>
          </div>
      </div>
  </Layout>
)

export default NotFoundPage;
